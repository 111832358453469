body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --main-spacing: 15px 5px 10px 5px;
  --header-footer-spacing: 10px 5px; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  margin: 0 auto;
  max-width: 720px;
}

.app{
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
    'header'
    'main'
    'footer'
  ;
  > header, > footer {
    padding: var(--header-footer-spacing);
  }
  > header {
    grid-area: header;
  }
  > main {
    grid-area: main;
    padding: var(--main-spacing);
    overflow: auto;
    display: grid;
    /* grid-template-rows: 1fr;
    grid-template-columns: 1fr minmax(0, 600px) 1fr; */


    > section {
      grid-area: 1 / 2 / 1 / 3;
      margin-bottom: 5px;
    }
    > section.full {
      grid-area: 1 / 1 / 1 / 4
    }
  }
  > footer {
    grid-area: footer;
  }
}


.MessageMe{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.MessageSystem{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.MessageBubble{
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 0.5em;
  margin: 0.5em 0.5em;
  max-width: 700px;
}

.MessageBubbleMe{
  background: rgb(240, 240, 240);
}